// 申遗承诺
import SHZT from "./sn-table-column-shzt";
import DJZT from "./sn-table-column-djzt";

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: "ID",
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    prop: "TBNAME",
                    label: "图斑名称",
                    minWidth: 110,
                    showoverflowtooltip: true,
                },
                {
                    prop: "YEAR1",
                    label: "对比年份",
                    prop1: "YEAR2",
                    hyphen: "-",
                },
                {
                    prop: "TDFL",
                    label: "土地类型（变化前）",
                    minWidth: 100,
                },
                {
                    prop: "TDFLBHH",
                    label: "土地类型（变化后）",
                    minWidth: 100,
                },
                {
                    prop: "SDLX",
                    label: "新建涉建项目",
                    minWidth: 110,
                },
                {
                    prop: "PGMC",
                    label: "评估",
                },
                {
                    prop: "RKSJ",
                    label: "时间",
                    minWidth: 160,
                },
                SHZT,
                DJZT,
            ],
            // 操作列
            columnsBtn: {
                buttonData: [
                    {
                        isShow: true,
                        showMethod: "showSeeBtn",
                        btnType: "icon",
                        operateType: "columnSee",
                        title: "查看",
                        icon: "el-icon-view",
                        backColor: "transparent",
                        color: "#20a0ff",
                    },
                    {
                        isShow: true,
                        showMethod: "showEditBtn",
                        btnType: "icon",
                        operateType: "columnEdit",
                        title: "编辑",
                        icon: "el-icon-edit",
                        backColor: "transparent",
                        color: "green",
                    },
                    {
                        isShow: true,
                        showMethod: "showDelBtn",
                        btnType: "icon",
                        operateType: "columnDelete",
                        title: "删除",
                        icon: "el-icon-delete",
                        backColor: "transparent",
                        color: "red",
                    },
                    {
                        prop: "SHZT",
                        label: "数据状态",
                        minWidth: 80,
                        transList: {
                            0: "已提交",
                            1: "未提交",
                        },
                    },
                ],
            },
        },
    },
    snSearch: {
        inputData: [
            {
                label: "关键字",
                placeholder: "请输入关键字",
                value: "keyword",
                operateType: "search",
                isShow: true,
            },
        ],
    },
    snButton: {
        buttonData: [
            {
                isShow: true,
                btnType: "button",
                operateType: "buttonNew",
                showMethod: "showNewBtn",
                name: "新建",
                round: true,
                backColor: "#28ccd9",
            },
            {
                btnType: "button",
                operateType: "buttonExport",
                showMethod: "showExportBtn",
                name: "导出",
                round: true,
                backColor: "#ffcc66",
                color: "#fff",
            },
        ],
    },
};
export default selfData;
